window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.Popper = require('@popperjs/core');

window.tempusDominus = require('@eonasdan/tempus-dominus');

const Inputmask = require('inputmask').default;
Inputmask.extendAliases({
    currency: {
        prefix: '$',
        groupSeparator: '',
        allowMinus: false,
    },
    integer: {
        allowMinus: false,
        placeholder: '',
    },
    decimal: {
        digits: 2,
    },
});

window.Inputmask = Inputmask;

window.lottie = require('lottie-web');
